import { useEffect, useState } from "react";
import { NavBarCont, NavTitle, NavTabBar, NavTab } from "../StyledApp";

function NavBar({ selected, scrollTopPage, clickButton, scrollToElement }) {
  const [nav, setNav] = useState(false);

  useEffect(() => {
    if (nav === "about") {
      setTimeout(() => {
        setNav(false);
      }, 505);
      scrollToElement("about", -80);
    }
    if (nav === "contact") {
      setTimeout(() => {
        setNav(false);
      }, 505);
      scrollToElement("contact", -80);
    }
  }, [nav, scrollToElement]);

  return (
    <NavBarCont>
      <NavTitle onClick={() => scrollTopPage()}>Thomas Luff</NavTitle>
      <NavTabBar>
        <NavTab
          onClick={() => {
            if (selected !== "") {
              clickButton("");
              setNav("about");
            } else {
              scrollToElement("about", -120);
            }
          }}
        >
          About
        </NavTab>
        <NavTab
          onClick={() => {
            if (selected !== "") {
              clickButton("");
              setNav("contact");
            } else {
              scrollToElement("contact");
            }
          }}
        >
          Contact
        </NavTab>
      </NavTabBar>
    </NavBarCont>
  );
}

export default NavBar;
