import {
  SectionContent,
  SectionContentTitle,
  SectionContentText,
  CodeTitle,
  ProjectSection,
  ProjectLeftSection,
  ProjectTitle,
  ProjectDescription,
  ProjectTechStackTitle,
  ProjectTechStackContainer,
  ProjectTechStackItem,
  ProjectLinkContainer,
  ProjectLink,
  ProjectImage,
  TechStackSection,
  TechStackRow,
  TechStackContainer,
  // TechStackTitle,
  // TechStackList,
  // TechStackListItem,
} from "../StyledApp";
import Footer from "./Footer";

function Code({ selected }) {
  const projectList = [
    {
      title: "Dorris",
      description:
        "Working as a full stack developer building a chatbot builder/creation tool from start to MVP, used in areas such as long online forms and call centres. API-driven backend using Node, SQL and GCP functions. Frontend uses React, react hooks, mobx-state-tree and styled components. All code is tested in either jest or cypress and work was done in an agile environment with daily standups and using jira to project manage.",
      techStack: [
        "React",
        "Node",
        "SQL",
        "GCP",
        "mobX-state-tree",
        "Styled components",
      ],
      links: [
        {
          label: "Project video",
          link: "https://www.youtube.com/watch?v=VOK3noTXXjk&ab_channel=Northcoders",
        },
      ],
      imageLink:
        "https://pixelportfolio.onrender.com/static/media/SSDorris.9550cf41.png",
    },
    {
      title: "Tien Len",
      description:
        "Vietnamese game where players race to get rid of their cards. Works live for multiple people to join and play.",
      techStack: ["React", "Firebase"],
      links: [
        {
          label: "Game Link",
          link: "https://tien-len.netlify.app/",
        },
        {
          label: "Github Repository",
          link: "https://github.com/TALLuff/Tien-Len-Multi",
        },
      ],
      imageLink:
        "https://pixelportfolio.onrender.com/static/media/SSTienLen.f79f3318.png",
    },
  ];

  const techStackList = [
    {
      title: "- Front End:",
      techList: [
        "Javascript",
        "React",
        "HTML",
        "CSS",
        "styled-components",
        "Axios",
        "Mobx-state-tree",
        "Formik",
      ],
    },
    {
      title: "- Back End:",
      techList: [
        "Node.js",
        "Express",
        "Knex",
        "JOI",
        "PSQL",
        "Firebase",
        "GCP",
      ],
    },
    { title: "- Testing:", techList: ["Jest (with API mocks)", "Cypress"] },
    {
      title: "- Experience with:",
      techList: [
        "Unity - C#",
        "Photoshop",
        "Krita",
        "Automation Anywhere",
        "Blender",
      ],
    },
  ];

  return (
    <SectionContent selected={selected === "Code"}>
      <SectionContentTitle selected={selected === "Code"}>
        Code
      </SectionContentTitle>
      <SectionContentText>
        Full stack software developer with commercial project experience.
        Comfortable working in a team with TDD and agile development. My pathway
        of programming started off with online tutorials and self learning, then
        after finding and graduating Northcoders I was able to start work on a
        new project which our team took from start to MVP. Personally
        programming allows me to both be creative and problem solve, and with
        interesting new skills to learn and exciting projects to work on I’m
        super excited to see what’s next.
      </SectionContentText>
      <CodeTitle id="projects" selected={selected === "Code"}>
        Projects
      </CodeTitle>
      {projectList.map((projObj, index) => {
        return (
          <ProjectSection selected={selected === "Code"} key={index}>
            <ProjectLeftSection>
              <ProjectTitle>{projObj.title}</ProjectTitle>
              <ProjectDescription>{projObj.description}</ProjectDescription>
              <ProjectTechStackTitle>- Tech used:</ProjectTechStackTitle>
              <ProjectTechStackContainer>
                {projObj.techStack.map((tech, index) => {
                  return (
                    <ProjectTechStackItem first={index === 0} key={index}>
                      {tech}
                    </ProjectTechStackItem>
                  );
                })}
              </ProjectTechStackContainer>
              <ProjectLinkContainer>
                {projObj.links.map((linkObj, index) => {
                  return (
                    <ProjectLink
                      target="_blank"
                      href={linkObj.link}
                      key={index}
                    >
                      {linkObj.label}
                    </ProjectLink>
                  );
                })}
              </ProjectLinkContainer>
            </ProjectLeftSection>
            <ProjectImage src={projObj.imageLink} />
          </ProjectSection>
        );
      })}
      <CodeTitle id="techStack" selected={selected === "Code"}>
        Tech Stack
      </CodeTitle>
      <TechStackSection selected={selected === "Code"}>
        {techStackList.map((techTypeObj, index) => {
          return (
            <TechStackContainer first={index === 0} key={index}>
              <ProjectTechStackTitle>{techTypeObj.title}</ProjectTechStackTitle>
              <TechStackRow>
                {techTypeObj.techList.map((tech, index) => {
                  return (
                    <ProjectTechStackItem first={index === 0} key={index}>
                      {tech}
                    </ProjectTechStackItem>
                  );
                })}
              </TechStackRow>
            </TechStackContainer>
          );
        })}
      </TechStackSection>
      <Footer />
    </SectionContent>
  );
}

export default Code;
