import styled from "styled-components";
import Lightbox from "react-spring-lightbox";

export const NavBarCont = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 4;
  background-image: linear-gradient(rgb(30, 30, 30, 1), rgb(100, 100, 100, 0));
  /* background-color: rgb(30, 30, 30, 0.5); */
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const NavTitle = styled.h1`
  color: white;
  margin: 0 15px;
  font-family: cinzel-medium;
  cursor: pointer;
  @media (max-width: 750px) {
    font-size: 1.6rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const NavTabBar = styled.h2`
  display: flex;
  justify-content: space-between;
`;

export const NavTab = styled.label`
  color: white;
  margin: 0 15px;
  font-family: cinzel-medium;
  cursor: pointer;
  @media (max-width: 750px) {
    font-size: 1.6rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const HomeBack = styled.div`
  width: 100vw;
`;

export const HomePage = styled.div`
  border: none;
  margin: none;
  display: flex;
  width: 100vw;
  justify-content: space-between;
`;

export const HomePanel = styled.button`
  border: none;
  margin: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: ${(props) => props.height};
  transition: 0.3s;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  color: ${(props) => (props.selected ? "white" : "rgb(10, 10, 10)")};
  flex-grow: ${(props) => (props.selected ? 4 : 1)};
  background-color: rgb(70, 70, 70);
  order: ${(props) => props.order};
  flex-basis: 20vw;
  overflow: hidden;
  cursor: pointer;
  :hover {
    transition: 0.3s;
    opacity: ${(props) => (props.selected ? 1 : 0.8)};
    flex-grow: ${(props) => props.flexGrow * 3};
    background-color: rgb(70, 70, 70);
    color: white;
    text-shadow: 0px 0px 10px rgb(29, 29, 29);
  }
`;

export const HomePanelBack = styled.div`
  width: 100%;
  height: 100%;
`;

export const SectionTitleContent = styled.label`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.selected ? "25vh" : "35vh")};
  transition: 0.5s;
`;

export const SectionTitle = styled.h1`
  font-family: cinzel-medium;
  margin: 0;
  padding-bottom: ${(props) => (props.selected ? "1rem" : "-2em")};
  transition: 0.5s, color 0s, text-shadow 0s;
  font-size: ${(props) => (props.selected ? "3em" : "2.5em")};
  letter-spacing: ${(props) => (props.selected ? "0.8em" : "0.2em")};
  padding-left: ${(props) => (props.selected ? "0.8em" : "0.2em")};
  text-align: center;
  cursor: pointer;
  text-shadow: ${(props) =>
    props.selected
      ? "0px 0px 8px rgb(15, 15, 15, 0.5), 0px 0px 4px rgb(100, 100, 100,0.5)"
      : "0px 0px 8px rgb(15, 15, 15, 0.2), 0px 0px 4px rgb(100, 100, 100, 0.2)"};
  @media (max-width: 750px) {
    font-size: 1.6rem;
  }
  @media (max-width: 450px) {
    font-size: 1.4rem;
  }
  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
`;

export const SectionFlourish = styled.label`
  transition: 0.5s;
  opacity: ${(props) => (props.selected ? "1" : "0")};
  border-top: 1px solid white;
  width: ${(props) => (props.selected ? "10em" : "2em")};
`;

export const SectionActivate = styled.label`
  font-family: cinzel-medium;
  text-shadow: 0px 0px 10px rgb(29, 29, 29);
  font-size: 1.1rem;
  padding: 0.5rem;
  transition: 0.3s;
  opacity: ${(props) => (props.selected ? "1" : "0")};
  z-index: 3;
  width: 100%;
  max-width: 40vw;
  cursor: pointer;
  :hover {
    transition: 0.3s;
    letter-spacing: 2px;
    text-shadow: 0px 0px 5px rgb(15, 15, 15, 0.8),
      0px 0px 2px rgb(10, 10, 10, 0.8);
    color: white;
  }
  @media (max-width: 750px) {
    font-size: 1rem;
  }
  @media (max-width: 450px) {
    font-size: 0.85rem;
  }
  @media (max-width: 400px) {
    font-size: 0.75rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(230, 230, 230);
`;

export const SectionContent = styled.div`
  opacity: ${(props) => (props.selected ? "1" : "0")};
  transition: 0.5s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  padding-top: ${(props) => (props.selected ? "78vh" : "74vh")};
  pointer-events: ${(props) => (props.selected ? "auto" : "none")};
  //maybe tweak height this is just to stop bug of text showing when switching pages
  height: ${(props) => (props.selected ? "auto" : "auto")};
  overflow-y: ${(props) => (props.selected ? "visible" : "hidden")};
  overflow-x: hidden;
  background-color: rgb(40, 40, 40);
  z-index: -1;
  left: 0;
  right: 0;
`;

export const NoSectionArrow = styled.label`
  margin-top: 7vh;
  font-family: "Courier New", Courier, monospace;
  font-size: 5rem;
`;

export const NoSectionText = styled.label`
  font-style: italic;
  position: relative;
  top: -3vh;
`;

export const SectionContentTitle = styled.h1`
  font-family: cinzel-medium;
  padding: 1rem;
  margin-bottom: 0;
  font-size: 5rem;
  transition: 0.5s;
  letter-spacing: ${(props) => (props.selected ? "0.3em" : "0")};
  @media (max-width: 750px) {
    font-size: 4rem;
  }
  @media (max-width: 450px) {
    font-size: 3rem;
  }
`;

export const SectionContentText = styled.p`
  width: 80vw;
  max-width: 30rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
`;

export const AboutSection = styled.div`
  padding-top: 8rem;
  display: flex;
  align-items: start;
  justify-content: center;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const AboutImage = styled.img`
  max-height: 50vh;
  max-width: 40vw;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 0.9;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
  @media (max-width: 750px) {
    max-width: 80vw;
  }
`;

export const AboutTitle = styled.h1`
  font-family: cinzel-medium;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  letter-spacing: 0.2em;
  padding-left: 3rem;
  @media (max-width: 750px) {
    padding-left: 0;
    padding-top: 1rem;
  }
`;

export const AboutText = styled.p`
  max-width: 400px;
  width: 30vw;
  padding-left: 3rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-style: italic;
  @media (max-width: 750px) {
    width: 80vw;
    padding-left: 0;
  }
`;

export const ResumeTitle = styled.h1`
  font-family: cinzel-medium;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  letter-spacing: 0.2em;
  text-align: center;
  @media (max-width: 750px) {
    padding-left: 0;
    padding-top: 1rem;
  }
`;

export const ResumeSection = styled.div`
  padding-top: 8rem;
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ResumeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ResumePage = styled.img`
  cursor: pointer;
  width: 20vw;
  max-width: 500px;
  margin: 2rem 0.8rem 0 0.8rem;
  transition: 0.5s;
  :hover {
    opacity: 0.8;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0;
  max-width: 90vw;
`;

export const ContactTitle = styled.h1`
  font-family: cinzel-medium;
  padding: 0;
  font-size: 3rem;
  letter-spacing: 0.4em;
  @media (max-width: 750px) {
    font-size: 2.4rem;
  }
  @media (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const ContactText = styled.p`
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande";
  letter-spacing: 1px;
  font-size: 0.8rem;
  text-align: center;
`;

export const ContactImagesSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactImageLink = styled.a`
  position: relative;
  margin: 2rem;
  cursor: pointer;
`;

export const PortfolioSection = styled.div`
  display: ${(props) => (props.selected ? "flex" : "none")};
  transition: 0.2s;
  margin-top: 8rem;
  height: 80vh;
  width: 90vw;
  @media (max-width: 1024px) {
    height: 70vh;
  }
  @media (max-width: 768px) {
    height: 60vh;
  }
  @media (max-width: 425px) {
    height: 55vh;
  }
`;

export const PortfolioInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow-x: scroll;
  padding: 0 20vw 0 10vw;
  position: relative;
`;

export const PortfolioTitle = styled.h2`
  font-family: cinzel-medium;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  letter-spacing: 0.1em;
  padding-right: 2rem;
  @media (max-width: 750px) {
    font-size: 1.7rem;
  }
  @media (max-width: 450px) {
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.4rem;
  }
`;

export const PortfolioText = styled.p`
  max-width: 300px;
  width: 20vw;
  min-width: 10rem;
  padding-right: 2rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  font-size: 0.8rem;
  font-style: italic;
  @media (max-width: 450px) {
    width: 40vw;
  }
`;

export const PortfolioImage = styled.img`
  content: url(${(props) => props.source});
  position: relative;
  height: 100%;
  max-height: 80vh;
  object-fit: cover;
  cursor: pointer;
`;

export const ImageLabel = styled.label`
  position: absolute;
`;

export const SlideshowBar = styled.div`
  opacity: ${(props) => (props.selected ? "1" : "0")};
  margin: 18px 0 8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 40, 40, 0.8);
`;

export const SlideshowPages = styled.label`
  transition: 0.5s;
  font-family: cinzel-medium;
  padding: 0.2rem 0.5rem;
  margin: 0.2rem 0.5rem;
`;

export const SlideshowLabel = styled.button`
  background: none;
  border: none;
  transition: 0.2s;
  color: white;
  font-family: cinzel-medium;
  padding: 0.2rem 0.5rem;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
  :hover {
    color: grey;
  }
`;

export const GameSection = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1400px;
  width: 65vw;
  @media (max-width: 1600px) {
    width: 80vw;
  }
  @media (max-width: 1024px) {
    width: 90vw;
    flex-direction: column;
  }
  padding: 4rem 0;
`;

export const GameLeftSection = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 65vw;
  @media (max-width: 1600px) {
    max-width: 80vw;
  }
  @media (max-width: 1024px) {
    max-width: 90vw;
  }
  height: ${(props) => (props.selected ? "auto" : "0")};
  opacity: ${(props) => (props.selected ? "1" : "0")};
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export const GameRightSection = styled(GameLeftSection)`
  justify-content: space-around;
`;

export const GameTitle = styled.h1`
  font-family: cinzel-medium;
  padding: 0.5rem 1rem 0 1rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  letter-spacing: 0.1em;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 2.2rem;
  }
`;

export const GameSubHeader = styled.h3`
  font-family: cinzel-medium;
  padding: 0 1rem;
  margin-top: 0;
  font-size: 1rem;
  letter-spacing: 0.1em;
  @media (max-width: 720px) {
    font-size: 0.9rem;
  }
`;

export const GameDescItch = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const GameDescription = styled.div`
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
`;

export const GameItchCard = styled.div`
  display: ${(props) => (props.bigDisplay ? "inline" : "none")};
  @media (max-width: 550px) {
    display: ${(props) => (props.bigDisplay ? "none" : "inline")};
  }
`;

export const GameVideo = styled.iframe`
  margin: 1rem;
`;

export const GameScreenshotsSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width};
  height: ${(props) => (props.width / 4.1) * 0.53};
  margin: 1rem;
`;

export const GameScreenshot = styled.img`
  height: ${(props) => props.width * 0.53};
  width: ${(props) => props.width};
  margin: 0.1rem;
  cursor: pointer;
`;

export const GameProgressLabel = styled.label``;

export const GameProgressSection = styled.div``;

export const GameProgressDecription = styled.p``;

export const GameProgressImage = styled.img``;

export const ProjectSection = styled.div`
  display: ${(props) => (props.selected ? "flex" : "none")};
  justify-content: center;
  align-items: stretch;
  width: 65vw;
  @media (max-width: 1600px) {
    width: 80vw;
  }
  @media (max-width: 1024px) {
    width: 90vw;
    flex-direction: column;
    align-items: center;
  }
  padding: 4rem 0;
`;

export const ProjectLeftSection = styled.div`
  @media (max-width: 1400px) {
    max-width: 50vw;
  }
  @media (max-width: 1024px) {
    max-width: 90vw;
  }
`;

export const CodeTitle = styled.h1`
  display: ${(props) => (props.selected ? "inline" : "none")};
  font-family: cinzel-medium;
  margin-top: 4rem;
  margin-bottom: 0;
  font-size: 3rem;
  letter-spacing: 0.1em;
  padding: 0 1rem;
  margin-left: 17.5vw;
  @media (max-width: 1600px) {
    margin-left: 10vw;
  }
  @media (max-width: 1024px) {
    margin-left: 5vw;
  }
  @media (max-width: 750px) {
    font-size: 2.5rem;
  }
  @media (max-width: 450px) {
    font-size: 2.2rem;
  }
  @media (max-width: 400px) {
    font-size: 2rem;
  }
  align-self: flex-start;
`;

export const ProjectTitle = styled.h1`
  font-family: cinzel-medium;
  padding: 0.5rem 1rem 0 1rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  letter-spacing: 0.1em;
`;

export const ProjectDescription = styled.p`
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
`;

export const ProjectTechStackTitle = styled.h3`
  font-family: cinzel-medium;
  padding: 0.5rem 1rem 0 1rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  letter-spacing: 0.1em;
`;

export const ProjectTechStackContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProjectTechStackItem = styled.p`
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  padding: 0.2rem 0 0 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  border-left: ${(props) => (props.first ? "solid 1px" : "solid 1px")};
`;

export const ProjectLinkContainer = styled.div`
  display: flex;
  justify-self: flex-end;
  padding: 1rem 0.5rem;
  @media (max-width: 370px) {
    flex-direction: column;
  }
`;

export const ProjectLink = styled.a`
  background: rgb(255, 150, 40);
  text-decoration: none;
  border: none;
  border: outset 3px lightgrey;
  color: white;
  font-family: cinzel-medium;
  font-size: 0.8rem;
  letter-spacing: 0.1em;
  margin: 0.2rem;
  padding: 0.3rem;
  transition: 0.2s;
  /* color: white; */
  :hover {
    color: black;
    border: inset 3px darkgray;
  }
`;

export const ProjectImage = styled.img`
  max-width: 1000px;
  max-height: 650px;
  width: 50vw;
  height: 30vw;
  margin: 1rem;
  @media (max-width: 750px) {
    width: 70vw;
    height: 40vw;
  }
  @media (max-width: 500px) {
    width: 90vw;
    height: 50vw;
  }
`;

export const TechStackSection = styled.div`
  display: ${(props) => (props.selected ? "flex" : "none")};
  flex-direction: column;
  width: 65vw;
  @media (max-width: 1600px) {
    width: 80vw;
  }
  @media (max-width: 1024px) {
    width: 90vw;
  }
  padding: 4rem 0;
`;

export const TechStackRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const TechStackContainer = styled.div`
  border-top: ${(props) => (props.first ? "none" : "solid 1px")};
`;

export const TechStackTitle = styled.h1``;

export const TechStackList = styled.div``;

export const TechStackListItem = styled.div``;

export const FooterCont = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background-color: rgb(10, 10, 10);
`;

export const FooterDescription = styled.label`
  color: white;
  padding: 1rem;
`;

export const StyledLightbox = styled(Lightbox)`
  background: rgb(10, 10, 10, 0.5);
`;
