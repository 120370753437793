import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { animated, useTransition } from "@react-spring/web";

const ArrowButton = ({ disabled, onClick, position }) => {
  const transitions = useTransition(!disabled, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  });

  return transitions(
    (props, item) =>
      item && (
        <animated.div
          style={{
            ...props,
            zIndex: 999,
          }}
        >
          <ButtonControl onClick={onClick} position={position} type="button">
            {position === "left" && <IoIosArrowBack />}
            {position === "right" && <IoIosArrowForward />}
          </ButtonControl>
        </animated.div>
      )
  );
};

ArrowButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.oneOf(["left", "right"]).isRequired,
};

ArrowButton.defaultProps = {
  disabled: false,
};

export default ArrowButton;

const ButtonControl = styled.button`
  z-index: 10;
  background: none;
  border-style: none;
  font-size: 50px;
  cursor: pointer;
  padding: 0;
  margin: 2rem;
  color: white;
  transition: color 0.2s linear;
  position: absolute;
  left: ${({ position }) => (position === "left" ? 0 : "unset")};
  right: ${({ position }) => (position === "right" ? 0 : "unset")};
  :hover {
    color: grey;
  }
  :focus {
    outline: none;
    color: grey;
  }
`;
