import {
  SectionContent,
  SectionContentTitle,
  SectionContentText,
  PortfolioSection,
  PortfolioInnerContainer,
  PortfolioTitle,
  PortfolioText,
  PortfolioImage,
  SlideshowBar,
  SlideshowPages,
  SlideshowLabel,
} from "../StyledApp";
import Footer from "./Footer";

function Art({ selected, animateScrollTo, scrollCarousel, openLightbox }) {
  const characterImages = [
    {
      src: "https://cdn.discordapp.com/attachments/598365774125727764/843587648261062696/unknown.png",
      alt: "Yorick sketch",
    },
    {
      src: "https://cdn.discordapp.com/attachments/336305590748053505/842409635142696980/unknown.png",
      alt: "Warwick sketch",
    },
    {
      src: "https://cdn.discordapp.com/attachments/598365774125727764/849266474274914314/BloodMagic.png",
      alt: "Vampire painting",
    },
  ];
  const environmentImages = [
    {
      src: "https://cdn.discordapp.com/attachments/336305590748053505/859079054660730880/Gallery_art2.jpg",
      alt: "Dungeon",
    },
    {
      src: "https://cdn.discordapp.com/attachments/336305590748053505/859078604200869928/3.jpg",
      alt: "Hobbits",
    },
  ];
  const pixelImages = [
    {
      src: "https://img.itch.zone/aW1hZ2UvMTEyOTY1Ni82NTY3MDk2LnBuZw==/original/nqWkt6.png",
      alt: "Slime sync start",
    },
    {
      src: "https://cdn.discordapp.com/attachments/598365774125727764/859112709232001025/unknown.png",
      alt: "Windows 10 Dark Mode Setting",
    },
  ];

  return (
    <SectionContent selected={selected === "Art"}>
      <SectionContentTitle selected={selected === "Art"}>
        Art
      </SectionContentTitle>
      <SectionContentText>
        {/* I have enjoyed drawing for years and continue to do so. I usually draw
        digitally with either krita or photoshop but have also been attending
        life drawing and trying to improve my observational skills. Recently I
        have also started learning 3D modelling in blender. */}
        Self taught with roughly 5 years of digital and traditional personal
        experience. When making games I use my own art and animations and hope
        to try out new different styles soon. I am also enthusiastic about many
        different art forms including illustration, animation and concept art.
      </SectionContentText>
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="CharPort">
          <div id="characters">
            <PortfolioTitle selected={selected === ""}>
              Characters
            </PortfolioTitle>
            <PortfolioText>
              {/* Character drawing is the area my art was initially drawn towards
              and have continued to improve with it, life drawing has helped
              also over the years and I still continue to practice this. The
              goal is to be able to draw professional looking character
              portraits and come up with concepts for making my games. */}
              Character drawing is the area in which I have the most experience,
              often influenced by my love for video games and fantasy worlds I
              try to illustrate that atmosphere and motion. Life drawing has
              also greatly helped this and I continue to use this a practice
              method.
            </PortfolioText>
          </div>
          {characterImages.map((charObj, index) => {
            return (
              <PortfolioImage
                id={"CharImg" + index}
                key={index}
                source={charObj.src}
                src={charObj.src}
                onClick={() => openLightbox(characterImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("characters"), {
              elementToScroll: document.getElementById("CharPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {characterImages.map((charObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel("CharImg" + index, "CharPort", "characters")
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="EnvironPort">
          <div>
            <PortfolioTitle selected={selected === ""} id="environments">
              Environments
            </PortfolioTitle>
            <PortfolioText>
              {/* Sweeping landscapes and fantasy themes always amaze me,
              environments are something I have very limited experience in but
              being I'd love to improve my knowledge in mainly in colours and
              values. */}
              Concept art landscapes and worlds often come first in the process
              of building out my game ideas and are a key part in setting the
              scene for the player. This is still an area I want to improve
              through studies and will personally continue to do so.
            </PortfolioText>
          </div>
          {environmentImages.map((envObj, index) => {
            return (
              <PortfolioImage
                id={"EnvironImg" + index}
                source={envObj.src}
                key={index}
                src={envObj.src}
                onClick={() => openLightbox(environmentImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("environments"), {
              elementToScroll: document.getElementById("EnvironPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {environmentImages.map((envObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel(
                  "EnvironImg" + index,
                  "EnvironPort",
                  "environments"
                )
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <PortfolioSection selected={selected === "Art"}>
        <PortfolioInnerContainer id="PixelPort">
          <div>
            <PortfolioTitle selected={selected === ""} id="pixels">
              Pixel-Art
            </PortfolioTitle>
            <PortfolioText>
              {/* When starting making my own games pixel art always was a style
              that felt comfortable and fascinating to me. It's deconstructive
              but with lots expression available in every small decision. In
              terms of inspiration owl boy/megaman and terraria are examples I
              hold in really high regard and would love to incorporate elements
              into my own projects. */}
              Pixel art is a style I value really highly, it has been
              instrumental in creating some of my favourite gaming experiences
              and I hope to continue that with my own. My own work is often
              between 16-bit and 32-bit which I find gives me enough detail to
              work with but doesn't take away from the charm of being able to
              see the individual pixels.
            </PortfolioText>
          </div>
          {pixelImages.map((pixObj, index) => {
            return (
              <PortfolioImage
                id={"PixelImg" + index}
                source={pixObj.src}
                src={pixObj.src}
                key={index}
                onClick={() => openLightbox(pixelImages, index)}
              />
            );
          })}
        </PortfolioInnerContainer>
      </PortfolioSection>
      <SlideshowBar selected={selected === "Art"}>
        <SlideshowPages>Pages:</SlideshowPages>
        <SlideshowLabel
          onClick={() =>
            animateScrollTo(document.getElementById("pixels"), {
              elementToScroll: document.getElementById("PixelPort"),
              horizontalOffset: -150,
            })
          }
        >
          0
        </SlideshowLabel>
        {pixelImages.map((pixelObj, index) => {
          return (
            <SlideshowLabel
              key={index}
              onClick={() =>
                scrollCarousel("PixelImg" + index, "PixelPort", "pixels")
              }
            >
              {index + 1}
            </SlideshowLabel>
          );
        })}
      </SlideshowBar>
      <Footer />
    </SectionContent>
  );
}

export default Art;
