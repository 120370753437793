import { FooterCont, FooterDescription } from "../StyledApp";

function Footer() {
  return (
    <FooterCont>
      <FooterDescription>Thomas Luff - 2021</FooterDescription>
    </FooterCont>
  );
}

export default Footer;
