// import { useState } from "react";
import {
  NoSectionArrow,
  NoSectionText,
  SectionContent,
  AboutSection,
  AboutTitle,
  AboutImage,
  AboutText,
  ResumeSection,
  ResumeTitle,
  ResumeContainer,
  ResumePage,
  ContactSection,
  ContactTitle,
  ContactText,
  ContactImagesSection,
  ContactImageLink,
} from "../StyledApp";
import Footer from "./Footer";
import { InstaWhiteSVG } from "../svg/instagramWhite";
import { InstaSVG } from "../svg/instagram";
import { ItchSVG } from "../svg/itch-io-brands";
import { LinkedInSVG } from "../svg/linkedin-brands";
import Resume from "../images/Resume.pdf";

function About({ selected }) {
  return (
    <SectionContent selected={selected === "" ? true : false}>
      <NoSectionArrow>^</NoSectionArrow>
      <NoSectionText>select a section</NoSectionText>
      <AboutSection>
        <AboutImage src="https://cdn.discordapp.com/attachments/598365774125727764/915930850053480539/1587183844198.png" />
        <div>
          <AboutTitle id="about">About</AboutTitle>
          <AboutText>
            {/* Hi, thank you so much for taking the time to look at my portfolio,
            in the three sections above there are examples of relevant work and
            links to view more or play. More examples can also be found in the
            social media links below. */}
            Hi, I'm a full a stack developer based in Manchester with a passion
            for art and game design. I have 2 years of experience as a software
            developer and 1 year as a self taught game developer, making my own
            art and sound assets to accompany this. Recently I was awarded the
            Best Solo Game in the GJL Game Parade Spring 2021 jam and nominated
            for Best Game Design and Best Level Design out of 316 participants.
            <br />
            <br />
            {/* Currently I am working on improving my indie game development
            skills, whether it is in this area or completely different if you
            think I would be a good fit for a job or collaberation please get in
            contact with me, I'm really looking forward to working with you! */}
            Currently I am working on expanding my portfolio and hope to join a
            team to gain experience working on newer and bigger projects while
            improving my skills to support this.
            <br />
            <br />
            -Tommy
          </AboutText>
        </div>
      </AboutSection>
      <ResumeSection>
        <div>
          <ResumeTitle id="about">Resume / CV</ResumeTitle>
          <ResumeContainer>
            <a href={Resume} target="_blank" rel="noreferrer">
              <ResumePage src="https://cdn.discordapp.com/attachments/598365774125727764/915932280650891325/unknown.png" />
            </a>
            <a href={Resume} target="_blank" rel="noreferrer">
              <ResumePage src="https://cdn.discordapp.com/attachments/598365774125727764/915932369154899998/unknown.png" />
            </a>
          </ResumeContainer>
        </div>
      </ResumeSection>
      <ContactSection>
        <ContactTitle id="contact">Contact</ContactTitle>
        <ContactText>
          {/* Please don't hesitate to get in contact if you have a question,
          looking forward to hearing from you :) */}
          To get in contact please email me at talluff@gmail.com
          <br />
          <br />
          You can find the most recent examples of my work below
        </ContactText>
        <ContactImagesSection>
          <ContactImageLink
            target="_blank"
            href="https://www.instagram.com/talluff/"
          >
            <InstaWhiteSVG />
            <InstaSVG />
          </ContactImageLink>
          <ContactImageLink target="_blank" href="https://talluff.itch.io/">
            <ItchSVG a="" />
          </ContactImageLink>
          <ContactImageLink
            target="_blank"
            href="https://www.linkedin.com/in/thomas-luff-32a880178"
          >
            <LinkedInSVG a="" />
          </ContactImageLink>
        </ContactImagesSection>
      </ContactSection>
      <Footer />
    </SectionContent>
  );
}

export default About;
